import { isNotNumber } from "./remyndUtils";

class NumberFormatHelpers {
  static instance: NumberFormatHelpers | null = null;
  private _numberFormatter: { [key: string]: Intl.NumberFormat } = {};

  static getInstance() {
    return this.instance ?? new NumberFormatHelpers();
  }

  private getNumberFormatterKey = (minDigits?: number, maxDigits?: number) =>
    `${minDigits}_${maxDigits}`;

  private initNumberFormatter(
    minDigits?: number,
    maxDigits?: number
  ): Intl.NumberFormat {
    const key = this.getNumberFormatterKey(minDigits, maxDigits);
    const formatter = Intl.NumberFormat("en", {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits
    });
    this._numberFormatter[key] = formatter;
    return formatter;
  }

  private getNumberFormatter(
    minDigits?: number,
    maxDigits?: number
  ): Intl.NumberFormat {
    const key = this.getNumberFormatterKey(minDigits, maxDigits);
    return (
      this._numberFormatter[key] ??
      this.initNumberFormatter(minDigits, maxDigits)
    );
  }

  formatNumberWithMinMaxDigits(
    number: number | string | null | undefined,
    minDigits = 2,
    maxDigits = 2
  ): string | undefined {
    if (isNotNumber(number)) {
      return undefined;
    } else if (typeof number !== "number") {
      number = Number(number);
    }
    const formatter = this.getNumberFormatter(minDigits, maxDigits);
    return formatter.format(number);
  }

  formatNumber(
    number: number | string | null | undefined,
    digits = 2
  ): string | undefined {
    return numberFormatHelpers.formatNumberWithMinMaxDigits(
      number,
      digits,
      digits
    );
  }
}
const numberFormatHelpers = NumberFormatHelpers.getInstance();
const formatNumberWithMinMaxDigits =
  numberFormatHelpers.formatNumberWithMinMaxDigits;
const formatNumber = numberFormatHelpers.formatNumber;

export { formatNumber, formatNumberWithMinMaxDigits };
