import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { Actions } from "src/apps/modules/auth/stores";
import { paths } from "src/apps/modules/partnerUser/utils";
import menus, { MenuTypes } from "../menus";

const container = {
  paddingRight: "16px",
  paddingLeft: "16px",
  marginRight: "auto",
  marginLeft: "auto"
};

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    color: "#555555",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...container,
    minHeight: "64px",
    alignItems: "center"
  },
  flex: {
    flex: 1
  },
  appResponsive: {
    top: "8px"
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  profileIcon: {
    marginRight: "5px",
    fontSize: "2rem"
  },
  textHeader: {
    paddingBottom: "4px",
    paddingLeft: "24px",
    color: theme.palette.primary.main
  }
}));

interface NavBarProps {
  handleDrawerToggle: () => void;
}

const resolvePathName = (currentPathName: string, menu: MenuTypes[]) => {
  const lv1MenuName = menu.find(
    m =>
      m.path === currentPathName ||
      (m.subPaths ?? []).some(subPath => subPath === currentPathName)
  )?.name;
  const lv2MenuName = menu
    .flatMap(m => m.subMenus ?? [])
    .find(
      m =>
        m.path === currentPathName ||
        (m.subPaths ?? []).some(subPath => subPath === currentPathName)
    )?.name;
  return lv1MenuName ?? lv2MenuName;
};

const NavBar = (props: NavBarProps) => {
  const classes = useStyles();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { userInfo } = useSelector((state: RootStore) => state.auths);
  const dispatch = useDispatch();
  const logout = () => dispatch(Actions.logoutRequest());

  const handleChangeMyPassword = () => {
    router.push(paths.changeMyPassword);
    handleMenuClose();
  };

  const currentPathName = resolvePathName(router.pathname, menus);

  const profileMenuId = "primary-search-account-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      getContentAnchorEl={null}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangeMyPassword}>Change Password</MenuItem>
      <Box mt={1} mb={1}>
        <Divider light />
      </Box>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.container}>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.flex}>
          <Typography variant="h1" className={classes.textHeader}>
            {currentPathName}
          </Typography>
        </div>
        <div>
          <Button
            aria-label="account of current user"
            aria-controls={profileMenuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            startIcon={<AccountCircleIcon />}
          >
            {userInfo?.fullName ?? "User"}
          </Button>
        </div>
        {renderProfileMenu}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
