const defaultLocale = "en-GB";
const dateFormat = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "numeric",
  year: "numeric"
});

const dateTimeFormat = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour12: false,
  hour: "2-digit",
  minute: "2-digit"
});

const dateTimeWithSecondFormat = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
});

const timeFormat = new Intl.DateTimeFormat(defaultLocale, {
  hour12: true,
  hour: "2-digit",
  minute: "2-digit"
});

export const parseToLocalDate = (date: Date): LocalDate => {
  return date.toISOString().split("T")[0];
};

export const parseToOffsetDateTime = (
  dateTimeString: string,
  adjust?: {
    sec?: number;
    ms?: number;
  }
): OffsetDateTime => {
  const date = new Date(dateTimeString);
  if (adjust?.sec != null) date.setSeconds(adjust.sec);
  if (adjust?.ms != null) date.setMilliseconds(adjust.ms);
  return date.toISOString();
};

export const formatDateTimeForTextField = (
  dateTimeString?: string | null
): string => {
  if (!dateTimeString) return "";

  const date = new Date(dateTimeString);
  const y = date.getFullYear();
  const m = date.getMonth();
  const d = date.getDate();
  const hh = date.getHours();
  const mm = date.getMinutes();
  const datePart = `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
  const timePart = `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}`;
  return `${datePart}T${timePart}`;
};

export const formatDate = (dateString: LocalDate): string => {
  const date = new Date(dateString);
  const result = dateFormat.format(date);
  return result;
};

export const formatDateTime = (
  dateTimeString: OffsetDateTime,
  withSeconds?: boolean
): string => {
  const date = new Date(dateTimeString);
  if (withSeconds) {
    return dateTimeWithSecondFormat.format(date);
  } else {
    return dateTimeFormat.format(date);
  }
};

export const formatTime = (dateTimeString: OffsetDateTime) => {
  const date = new Date(dateTimeString);
  return timeFormat.format(date);
};

export const addDays = (date: LocalDate | Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonths = (date: LocalDate | Date, months: number): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

export const addYears = (date: LocalDate | Date, years: number): Date => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
};
