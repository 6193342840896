import { useState, useEffect } from "react";

export const isNotNumber = (number: number | string | null | undefined) =>
  number === undefined ||
  number === null ||
  (typeof number === "number" && isNaN(number));

type DebounceStateType = {
  debouncing: boolean;
  value?: string;
};

export const useDebounce = (value: string | undefined, delay: number) => {
  // State and setters for debounced value
  const [debouncedState, setDebouncedState] = useState<DebounceStateType>({
    debouncing: false,
    value: value
  });

  useEffect(
    () => {
      setDebouncedState(d => ({ ...d, debouncing: true }));
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedState({ debouncing: false, value });
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  );

  return debouncedState;
};

export function toDropdown<T extends { id: string; label: string }>(
  obj: T
): IDropdown<T> {
  return {
    value: obj.id,
    label: obj.label,
    meta: obj
  };
}

export function toDropdownOrNull<T extends { id: string; label: string }>(
  obj: T | undefined | null
): IDropdown<T> | null {
  if (!obj) return null;
  return {
    value: obj.id,
    label: obj.label,
    meta: obj
  };
}
