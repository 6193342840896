import * as FileSaver from "file-saver";

export const downloadFile = async (file: Response, fileName?: string) => {
  const blob = await file.blob();
  if (!fileName) {
    const disposition = file.headers.get("content-disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
      }
    }
  }

  if (fileName) {
    FileSaver.saveAs(blob, fileName);
  } else {
    FileSaver.saveAs(blob);
  }
};
