import React from "react";
import { makeStyles } from "@material-ui/core";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const drawerWidth = 260;
const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: theme.palette.background.paper
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "15px 15px",
    backgroundColor: theme.palette.background.paper
  },
  container: {
    padding: "20px",
    minHeight: "calc(100vh - 100px)",
    backgroundColor: theme.palette.background.default
  }
}));

const MainLayout: React.FC<unknown> = props => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.wrapper}>
      <SideBar handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
      <div className={classes.mainPanel}>
        <Navbar handleDrawerToggle={handleDrawerToggle} />
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
