import {
  Apple as AppleIcon,
  Category as CategoryIcon,
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  Devices as DevicesIcon,
  SupervisorAccount as SupervisorAccountIcon,
  VerifiedUser as VerifiedUserIcon,
  VerticalSplit as VerticalSplitIcon,
  WidgetsOutlined as WidgetsOutlinedIcon
} from "@material-ui/icons";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

import {
  constants as userProductConstants,
  paths as userProductPaths
} from "src/apps/modules/userProduct/utils";
import {
  constants as partnerBrandConstants,
  paths as partnerBrandPaths
} from "src/apps/modules/partnerBrand/utils";
import {
  constants as partnerCategoryConstants,
  paths as partnerCategoryPaths
} from "src/apps/modules/partnerCategory/utils";
import {
  constants as partnerProductConstants,
  paths as partnerProductPaths
} from "src/apps/modules/partnerProduct/utils";
import {
  constants as partnerManualConstants,
  paths as partnerManualPaths
} from "src/apps/modules/partnerManual/utils";
import {
  constants as partnerWarrantyConstants,
  paths as partnerWarrantyPaths
} from "src/apps/modules/partnerWarranty/utils";
import {
  constants as partnerUserConstants,
  paths as partnerUserPaths
} from "src/apps/modules/partnerUser/utils";

interface CommonMenuType {
  name: string | JSX.Element;
  render?: JSX.Element;
  icon?: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
  path?: string;
  subPaths?: string[];
}

export interface MenuTypes extends CommonMenuType {
  subMenus?: SubMenuTypes[];
}

interface SubMenuTypes extends CommonMenuType {
  path: string;
}

const menus: MenuTypes[] = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/"
  },
  {
    name: userProductConstants.label,
    icon: WidgetsOutlinedIcon,
    path: userProductPaths.index,
    subPaths: [userProductPaths.edit]
  },
  {
    name: "จัดการข้อมูล Master",
    icon: VerticalSplitIcon,
    subMenus: [
      {
        name: partnerBrandConstants.label,
        icon: AppleIcon,
        path: partnerBrandPaths.index,
        subPaths: [partnerBrandPaths.create, partnerBrandPaths.edit]
      },
      {
        name: partnerCategoryConstants.label,
        icon: CategoryIcon,
        path: partnerCategoryPaths.index,
        subPaths: []
      },
      {
        name: partnerProductConstants.label,
        icon: DevicesIcon,
        path: partnerProductPaths.index,
        subPaths: [
          partnerProductPaths.create,
          partnerProductPaths.edit,
          partnerProductPaths.import
        ]
      },
      {
        name: partnerManualConstants.label,
        icon: DescriptionIcon,
        path: partnerManualPaths.index,
        subPaths: [
          partnerManualPaths.create,
          partnerManualPaths.edit,
          partnerManualPaths.show
        ]
      },
      {
        name: partnerWarrantyConstants.label,
        icon: VerifiedUserIcon,
        path: partnerWarrantyPaths.index,
        subPaths: [partnerWarrantyPaths.create, partnerWarrantyPaths.edit]
      }
    ]
  },
  {
    name: partnerUserConstants.label,
    icon: SupervisorAccountIcon,
    path: partnerUserPaths.index,
    subPaths: [
      partnerUserPaths.edit,
      partnerUserPaths.changePassword,
      partnerUserPaths.changeMyPassword
    ]
  }
];

export default menus;
