import React from "react";
import { useDispatch } from "react-redux";
import { HttpError } from "src/utils/apiUtils";
import useApiAlert from "src/hooks/useApiAlert";
import { Actions } from "src/apps/modules/auth/stores";

const useHttpErrorHandler = () => {
  const dispatch = useDispatch();
  const { setMessage } = useApiAlert();

  const handleError = React.useCallback(
    (e: HttpError | Error) => {
      if (e instanceof HttpError) {
        const { httpStatus, messages } = e;
        if (httpStatus === 401) {
          // Unauthorized
          dispatch(Actions.logoutRequest());
          setMessage("error", "Your session has expired.  Please relogin.");
        } else {
          setMessage("error", messages);
        }
      } else {
        setMessage("error", e.message);
      }
    },
    [dispatch, setMessage]
  );

  return { handleError };
};

export default useHttpErrorHandler;
