import React from "react";
import { useDispatch } from "react-redux";
import { Actions } from "src/apps/modules/alert/stores";
import { AlertType } from "src/apps/modules/alert/types";

const useApiAlert = () => {
  const dispatch = useDispatch();

  const setMessage = React.useCallback(
    (type: AlertType, messages: string | string[]) => {
      if (Array.isArray(messages)) {
        dispatch(Actions.alertRequest({ type, messages: messages }));
      } else {
        dispatch(Actions.alertRequest({ type, messages: [messages] }));
      }
    },
    [dispatch]
  );

  const clearMessage = React.useCallback(() => {
    dispatch(Actions.alertClear());
  }, [dispatch]);

  return { setMessage, clearMessage };
};

export default useApiAlert;
